<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold">
        Bookings ({{ listMeta.total }})
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 500px">
        <v-col class="col-5">
          <v-select
            class="ml-10 text-capitalize"
            hide-details
            dense
            :items="filterOptions"
            v-model="filter.field"
            style="max-width: 300px"
            outlined
          ></v-select>
        </v-col>

        <v-col>
          <v-text-field
            v-if="filter.field !== 'status'"
            class="search mr-5"
            ref="searchField"
            flat
            hide-details
            solo
            rounded
            clearable
            background-color="grey lighten-3"
            label="Search label"
            v-model="filter.search"
            @input="debouncedSearch"
          ></v-text-field>
          <v-select
            v-else
            class="ml-10 text-capitalize"
            hide-details
            dense
            :items="statusOptions"
            v-model="filter.search"
            style="max-width: 300px"
            @change="search"
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="field"
          v-model="sort"
          :value="sort.replace('-', ' ')"
          :items="sortFields"
          style="max-width: 200px"
          @change="filterRecords"
        />
        <v-btn
          depressed
          class="ml-2"
          :color="this.status ? 'primary' : ''"
          @click="
            sortOrder = true
            filterRecordsBTN()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="this.status === false ? 'primary' : ''"
          @click="
            sortOrder = false
            filterRecordsBTN()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="booking-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th>Booked By</th>
            <th>Booking Dates</th>
            <th>Bond</th>
            <th>Service Fee</th>
            <th>Total Payable</th>
            <th>Remaining Balance</th>
            <th>Booked Item</th>
            <th>Quantity</th>
            <th>Owner</th>
            <th>Status</th>
            <th>Booking Day</th>
            <th>Date Added</th>
            <th>Date Returned</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in records"
            :key="record.id"
            @click="
              $router.push({
                name: 'booking.edit',
                params: { id: record.id },
                query: {
                  sort:
                    filterData.sort === null
                      ? 'created_at'
                      : filterData.sort.toString(),
                  page:
                    filterData.page === null ? '1' : filterData.page.toString(),
                },
              })
            "
          >
            <span v-if="record.user != null">
              <v-tooltip top color="error" :disabled="!record.user.deleted_at">
                <template v-slot:activator="{ on, attrs }">
                  <td class="text-capitalize" v-bind="attrs" v-on="on">
                    <router-link
                      :class="{ 'red--text': record.user.deleted_at }"
                      :to="{
                        name: 'user.details',
                        params: { id: record.user_id },
                      }"
                      target="_blank"
                      ><span v-if="record.user">
                        {{ record.user.full_name }}
                      </span>
                    </router-link>
                  </td>
                </template>
                <span>Deleted Account</span>
              </v-tooltip>
            </span>
            <td v-else>--</td>
            <td class="white_space">
              {{ record.startDate + ' - ' + record.endDate }}
            </td>
            <td>
              {{ getMoneyFormat(record.bond) }}
            </td>
            <td>
              {{ getMoneyFormat(record.service_fee) }}
            </td>
            <td>
              {{ getMoneyFormat(record.total_amount) }}
            </td>
            <td>
              {{ getMoneyFormat(record.remaining_balance) }}
            </td>
            <td class="text-capitalize" v-if="record.listing != null">
              <router-link
                :to="{
                  name: 'listing.edit',
                  params: { id: record.listing.id },
                }"
                target="_blank"
                >{{ record.listing.title }}
              </router-link>
            </td>
            <td v-else>--</td>
            <td class="text-capitalize">
              {{ record.quantity }}
            </td>
            <v-tooltip
              v-if="record.listing != null"
              top
              color="error"
              :disabled="!record.listing.author.deleted_at"
            >
              <template v-slot:activator="{ on, attrs }">
                <td class="text-capitalize" v-bind="attrs" v-on="on">
                  <router-link
                    :class="{ 'red--text': record.listing.author.deleted_at }"
                    :to="{
                      name: 'user.details',
                      params: { id: record.listing.author.id },
                    }"
                    target="_blank"
                    >{{ record.listing.author.full_name }}
                  </router-link>
                </td>
              </template>
              <span>Deleted Account</span>
            </v-tooltip>
            <td v-else>--</td>

            <td class="text-capitalize">
              <span :class="record.status">{{ record.status }}</span>
            </td>
            <td class="text-capitalize">
              <span v-if="record.is_same_day == 0" class=""> Other Day</span>
              <span v-else class=""> Same Day</span>
            </td>

            <td class="text-capitalize white_space">
              {{ record.addedDate }}
            </td>
            <td class="text-capitalize white_space">
              {{ record.returnedDate }}
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(records.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { values } from 'lodash'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiCheck,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Currency from '@/utils/mixins/Currency'
import BookingStatus from '@/enums/booking-status'

export default {
  name: 'BookingsPage',

  mixins: [ControlsMixin, Currency],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
        field: 'search',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        check: mdiCheck,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      defaultFilter: 'start_date',
      sortOrder: true,
      // sort: 'start_date',
      sort:
        this.$route.query.sort === undefined
          ? 'start_date'
          : this.$route.query.sort,
      sortFields: [
        {
          label: 'Start Date',
          field: 'start_date',
        },
        {
          label: 'End Date',
          field: 'end_date',
        },
      ],
      filterOptions: [
        {
          text: 'Listing Title',
          value: 'search',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
      statusOptions: values(BookingStatus),
      filterData: {
        sort: '',
        page: '',
      },
      status: JSON.parse(sessionStorage.getItem('bookingToggle')),
    }
  },

  created() {
    this.clearRecordList()
    this.fetchRecords(1)
    this.debouncedSearch = debounce(() => {
      this.clearRecordList()
      this.fetchRecords()
    }, 600)
    this.status =
      JSON.parse(sessionStorage.getItem('bookingToggle')) === null
        ? true
        : JSON.parse(sessionStorage.getItem('bookingToggle'))
  },
  computed: {
    ...mapState({
      records: (state) => state.booking.list,
      listMeta: (state) => state.booking.listMeta,
    }),
  },

  methods: {
    ...mapActions({
      getRecords: 'booking/getRecords',
    }),

    ...mapMutations({
      clearRecordList: 'booking/clearList',
    }),

    pageChanged(page) {
      this.fetchRecords(page)
    },

    filterRecords() {
      this.status = JSON.parse(sessionStorage.getItem('bookingToggle'))
      this.$router.push({
        name: this.$route.name,
        query: {
          // ...this.$route.query,
          sort: this.sort,
          page: '1',
        },
      })
      this.clearRecordList()
      this.fetchRecords()
    },

    async filterRecordsBTN() {
      this.clearRecordList()
      sessionStorage.setItem('bookingToggle', this.sortOrder)
      this.status = JSON.parse(sessionStorage.getItem('bookingToggle'))
      this.loading = true
      let paramsData = {}
      if (JSON.stringify(this.$route.query) === '{}') {
        paramsData = {
          page: 1,
          // sort: this.defaultFilter,
          sort: this.status
            ? `${this.defaultFilter}`
            : `-${this.defaultFilter}`,
          include: ['author', 'category'],
        }
      } else {
        paramsData = {
          page: 1,
          include: ['author', 'category'],
          sort: this.status
            ? `${this.$route.query.sort}`
            : `-${this.$route.query.sort}`,
        }
      }

      this.loading = true
      sessionStorage.setItem('bookingFilter', JSON.stringify(paramsData))
      await this.getRecords(paramsData)
      this.loading = false
      const data = sessionStorage.getItem('bookingFilter')
      this.filterData = JSON.parse(data)
    },

    search() {
      this.clearRecordList()
      this.fetchRecords()
    },

    async fetchRecords(page = 1) {
      if (this.status === null) {
        this.status = true
      }

      if (this.loading) return
      let params
      if (JSON.stringify(this.$route.query) === '{}') {
        params = {
          page,
          sort: this.status ? `${this.sort}` : `-${this.sort}`,
        }
      } else {
        params = {
          page: parseInt(this.$route.query.page),
          sort: this.status
            ? `${this.$route.query.sort}`
            : `-${this.$route.query.sort}`,
        }
      }
      if (this.filter.search !== '') {
        params.filter = {
          search: this.filter.search,
          field: this.filter.field,
        }
      }

      params.include = ['author', 'category']
      // console.log(object)
      this.loading = true
      sessionStorage.setItem('bookingFilter', JSON.stringify(params))
      await this.getRecords(params)
      this.loading = false
      const data = sessionStorage.getItem('bookingFilter')
      this.filterData = JSON.parse(data)
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      // console.log(this.showSearch)
      sessionStorage.setItem('bookingFilterStatus', this.showSearch)
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    resetSearch() {
      this.filter.search = ''
      this.debouncedSearch()
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.$router
          .replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              sort: this.status
                ? `${this.defaultFilter}`
                : `-${this.defaultFilter}`,
              page: this.listMeta.current_page + 1,
            },
          })
          .catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
        this.fetchRecords(this.listMeta.current_page + 1)
      }
    },
  },

  watch: {
    'filter.field': function (newValue) {
      if (newValue && this.filter.search !== '') {
        this.debouncedSearch()
      }
    },
    showSearch(newValue) {
      if (newValue === false && this.filter.search !== '') {
        this.resetSearch()
      }
    },

    // sort: function (newValue) {
    //   this.filterRecords()
    // },
  },

  updated() {
    if (this.$route.query.sort) {
      this.$router
        .replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            sort: this.$route.query.sort.toString().replace('-', ' ').trim(),
            page: parseInt(this.$route.query.page),
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
      this.$route.query.sort.toString().replace('-', ' ')
    }
  },
}
</script>

<style Scoped>
table > tbody > tr:hover td {
  background: #eeeeee !important;
  cursor: pointer;
}
/* tr {
  white-space: nowrap;
} */
.completed {
  color: #1b5e20;
}
.current {
  color: #01579b;
}
.upcoming {
  color: #00897b;
}
.cancelled {
  color: #d50000;
}
@media (max-width: 600px) {
  .white_space {
    white-space: nowrap;
  }
}
</style>
